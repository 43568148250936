import React from "react";
import { isChristmasPeriod } from "@/lib/utils";
import { useTheme } from "@/providers/ThemeProvider";
import { useSidebar } from "@/components/ui/sidebar";

const QvirtualLogo = () => {
  return (
    <div className={"flex justify-center items-center h-12"}>
      <Logo />
    </div>
  );
};

const Logo = () => {
  const { theme } = useTheme();
  const { state } = useSidebar();
  const logoImage =
    theme === "light"
      ? state === "collapsed"
        ? "/assets/images/logos/logo-dark-square.png"
        : "/assets/images/logos/logo-dark-new.png"
      : state === "collapsed"
        ? "/assets/images/logos/logo-lite-square.png"
        : "/assets/images/logos/logo-lite-new.png";

  return isChristmasPeriod() ? (
    <img
      src={
        state === "collapsed"
          ? "/assets/images/logos/q-christmas.png"
          : "/assets/images/logos/logo-lite-new-christmas.png"
      }
      alt="QVirtual's Christmas logo"
      className="h-8"
    />
  ) : (
    <img src={logoImage} alt="QVirtual's logo" className="h-8" />
  );
};

export { QvirtualLogo };

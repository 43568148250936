import { AppSidebar } from "@/components/app-sidebar";
import { SidebarInset, SidebarTrigger } from "@/components/ui/sidebar";
import { Badge, Separator } from "@/components/ui";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { usePageData } from "@/hooks/usePageData";

export default function Authenticated({
  children,
}: {
  children: React.ReactNode;
}) {
  const isDev = import.meta.env.MODE === "development";
  const { breadcrumbs } = usePageData();

  return (
    <>
      <AppSidebar />
      <SidebarInset>
        <header className="flex h-12 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                {breadcrumbs.length > 0 ? (
                  breadcrumbs.map((breadcrumb, index) => {
                    const isLast = index === breadcrumbs.length - 1;
                    if (isLast) {
                      return (
                        <BreadcrumbItem key={breadcrumb.label + "-" + index}>
                          <BreadcrumbPage>{breadcrumb.label}</BreadcrumbPage>
                        </BreadcrumbItem>
                      );
                    }

                    return (
                      <>
                        <BreadcrumbItem
                          key={breadcrumb.label + "-" + index}
                          className="hidden md:block"
                        >
                          <BreadcrumbLink href={breadcrumb.link}>
                            {breadcrumb.label}
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator className="hidden md:block" />
                      </>
                    );
                  })
                ) : (
                  <BreadcrumbItem>
                    <BreadcrumbPage>Administration</BreadcrumbPage>
                  </BreadcrumbItem>
                )}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          {isDev && (
            <div className={"flex-1"}>
              <Badge variant={"destructive"}>Development build</Badge>
            </div>
          )}
        </header>
        <div className="h-[calc(100svh-48px)]">
          <div className="h-full flex flex-col">{children}</div>
        </div>
      </SidebarInset>
    </>
  );
}

import { ChevronRight } from "lucide-react";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { NavItem } from "@/hooks/usePageData";
import { Icon } from "@/components/Icon";
import { Link } from "@inertiajs/react";

export function NavMain({
  header,
  items,
}: {
  header?: string;
  items: NavItem[];
}) {
  return (
    <SidebarGroup>
      {header && <SidebarGroupLabel>{header}</SidebarGroupLabel>}
      <SidebarMenu>
        {items.map((item) =>
          item.submenu && item.submenu?.length > 0 ? (
            <Dropdown item={item} key={item.id} />
          ) : (
            <SidebarMenuItem key={item.id}>
              <SidebarMenuButton
                tooltip={item.label}
                asChild
                isActive={window.location.href.split("?")[0] === item.link}
              >
                <Link href={item.link as string}>
                  {item.icon && <Icon icon={item.icon} iconStyle={"SOLID"} />}
                  <span>{item.label}</span>
                </Link>
              </SidebarMenuButton>
              <SidebarMenuBadge>{item.badge}</SidebarMenuBadge>
            </SidebarMenuItem>
          ),
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}

const Dropdown = ({ item }: { item: NavItem }) => {
  return (
    <Collapsible
      key={item.id}
      asChild
      defaultOpen={false}
      className="group/collapsible"
    >
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton tooltip={item.label}>
            {item.icon && <Icon icon={item.icon} />}
            <span>{item.label}</span>
            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {item.submenu?.map((subItem) => (
              <SidebarMenuSubItem key={subItem.label}>
                <SidebarMenuSubButton asChild>
                  <a href={subItem.link as string}>
                    <span>{subItem.label}</span>
                  </a>
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
};

import React, { createContext, useContext, useState } from "react";

type previousTableDataType = {
  totalCount: number;
  objectId: number;
  routeKey: string;
  currentPage: number;
  perPage: number;
};

type previousTableRouteObjectType = {
  routeName: string;
  routeParams: Record<string, unknown>;
};

type TableServiceContextType = {
  previousTableData: previousTableDataType[] | null;
  setPreviousTableDataState: React.Dispatch<
    React.SetStateAction<previousTableDataType[] | null>
  >;
  setPreviousTableData: (value: previousTableDataType[]) => void;
  previousTableRouteObject: previousTableRouteObjectType | null;
  setPreviousTableRouteObject: React.Dispatch<
    React.SetStateAction<previousTableRouteObjectType | null>
  >;
};

const TableServiceContext = createContext<TableServiceContextType>(null!);

export const TableServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [previousTableRouteObject, setPreviousTableRouteObject] = useState<{
    routeName: string;
    routeParams: Record<string, unknown>;
  } | null>(null);

  const [previousTableData, setPreviousTableDataState] = useState<
    previousTableDataType[] | null
  >(() => {
    const storedValue = localStorage.getItem("bcNavigation");
    if (storedValue === null) {
      return null;
    }
    return JSON.parse(storedValue);
  });

  const setPreviousTableData = (value: previousTableDataType[]) => {
    localStorage.setItem("bcNavigation", JSON.stringify(value));
    setPreviousTableDataState(value);
  };

  return (
    <TableServiceContext.Provider
      value={{
        previousTableData,
        setPreviousTableDataState,
        setPreviousTableData,
        previousTableRouteObject,
        setPreviousTableRouteObject,
      }}
    >
      {children}
    </TableServiceContext.Provider>
  );
};

export const useTableServiceContext = () => {
  const context = useContext(TableServiceContext);
  if (context === undefined) {
    throw new Error(
      "useTableServiceContext must be used within a TableServiceProvider",
    );
  }
  return context;
};

import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useState,
} from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { TODO } from "@/types";

interface TawkMessengerContextType {
  toggleChat: () => void;
  isLoaded: boolean;
}

const TawkMessengerContext = createContext<
  TawkMessengerContextType | undefined
>(undefined);

export const TawkMessengerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const tawkMessengerRef = useRef<TODO>();
  const [isLoaded, setIsLoaded] = useState(false);

  const toggleChat = () => {
    if (import.meta.env.DEV) {
      alert("Chat is disabled in development mode");
      return;
    }
    window.Tawk_API.toggle();
  };

  return (
    <TawkMessengerContext.Provider value={{ toggleChat, isLoaded }}>
      <div className="hidden">
        {!import.meta.env.DEV && (
          <TawkMessengerReact
            customStyle={{ display: "none" }}
            propertyId="62a07c397b967b117993777c"
            widgetId="1g51eagjt"
            useRef={tawkMessengerRef}
            onBeforeLoad={() => {
              window.Tawk_API.hideWidget();
            }}
            onLoad={() => {
              setIsLoaded(true);
            }}
            onChatMessageAgent={() => {
              window.Tawk_API.maximize();
            }}
            onChatMessageSystem={() => {
              window.Tawk_API.maximize();
            }}
          />
        )}
      </div>
      {children}
    </TawkMessengerContext.Provider>
  );
};

export const useTawkMessenger = (): TawkMessengerContextType => {
  const context = useContext(TawkMessengerContext);
  if (context === undefined) {
    throw new Error(
      "useTawkMessenger must be used within a TawkMessengerProvider",
    );
  }
  return context;
};

import * as React from "react";

import { NavMain } from "@/components/nav-main";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarRail,
} from "@/components/ui/sidebar";
import { QvirtualLogo } from "@/components/qvirtual-logo";
import { Icon } from "@/components/Icon";
import { useTheme } from "@/providers/ThemeProvider";
import { useTawkMessenger } from "@/providers/TawkMessengerProvider";
import { useQvNavigation } from "@/hooks/useQvNavigation";
import { NavUser } from "@/components/nav-user";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { theme, toggleTheme } = useTheme();
  const { toggleChat } = useTawkMessenger();
  const { data } = useQvNavigation();
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <QvirtualLogo />
      </SidebarHeader>
      <SidebarContent>
        {data &&
          data?.map(({ section, items }, idx) => (
            <NavMain header={section} items={items} key={idx} />
          ))}
      </SidebarContent>
      <SidebarFooter>
        <SidebarGroup>
          <SidebarMenu>
            <SidebarMenuButton
              tooltip={"Discord"}
              onClick={() => {
                const link = "https://discord.qvirtual.com.au";
                window.open(link, "_blank");
              }}
            >
              <Icon icon={"fa-discord fa-brands"} iconStyle="SOLID" />
              <span>Discord</span>
            </SidebarMenuButton>
            <SidebarMenuButton
              tooltip={"QCommunity"}
              onClick={() => {
                const link = "https://community.qvirtual.com.au";
                window.open(link, "_blank");
              }}
            >
              <Icon icon={"fa-message"} iconStyle="SOLID" />
              <span>QCommunity</span>
            </SidebarMenuButton>
            <SidebarMenuButton tooltip={"Support"} onClick={toggleChat}>
              <Icon icon={"fa-circle-question"} iconStyle="SOLID" />
              <span>Support</span>
            </SidebarMenuButton>
            <SidebarMenuButton
              tooltip={"Toggle theme"}
              onClick={() => toggleTheme()}
            >
              {theme === "light" ? (
                <Icon icon={"fa-moon"} iconStyle="SOLID" />
              ) : (
                <Icon icon={"fa-sun-bright"} />
              )}
              <span>Toggle theme</span>
            </SidebarMenuButton>
          </SidebarMenu>
        </SidebarGroup>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
